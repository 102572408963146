import React from "react";
import {SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {useAccordionContext} from "../hooks/use_accordion_context";
import {useContentHeight} from "../hooks/use_content_height";

export interface IProps {
    children: React.ReactNode;
    className?: string;
    wrapCss?: SerializedStyles | ((theme: Theme) => SerializedStyles);
}

export const Content: React.FC<IProps> = (props) => {
    const context = useAccordionContext(Content.name);
    const {contentRef, contentHeight} = useContentHeight();

    return (
        <ContentAnimation css={props.wrapCss} collapsed={context.collapsed} maxHeight={contentHeight ? `${contentHeight}px` : undefined}>
            <ContentBase
                ref={contentRef}
                background={context.background}
                bordered={context.bordered}
                borderRadius={context.borderRadius}
                className={props.className}
                color={context.color}
                collapsed={context.collapsed}
            >
                {props.children}
            </ContentBase>
        </ContentAnimation>
    );
};

const ContentAnimation = styled.div<{collapsed: boolean; maxHeight?: string} & IProps>`
    height: ${(props) => (props.collapsed ? "0" : props.maxHeight || "auto")};
    transition: 0.3s ease-out;
    overflow: hidden;
`;

const ContentBase = styled.div<{background?: string; bordered: boolean; borderRadius?: string; collapsed: boolean; color?: string} & IProps>`
    background-color: ${(props) => props.background || "#fff"};
    padding: 1.6rem;
    border-radius: ${(props) => props.borderRadius || "0 0 1.6rem 1.6rem"};
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.secondary}` : "none")};
    border-top: none;
    color: ${(props) => props.color || "initial"};
`;
import React from "react";
import {SerializedStyles, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {useAccordionContext} from "../hooks/use_accordion_context";
import {useContentHeight} from "../hooks/use_content_height";

export interface IProps {
    children: React.ReactNode;
    className?: string;
    wrapCss?: SerializedStyles | ((theme: Theme) => SerializedStyles);
}

export const Content: React.FC<IProps> = (props) => {
    const context = useAccordionContext(Content.name);
    const {contentRef, contentHeight} = useContentHeight();

    return (
        <ContentAnimation css={props.wrapCss} collapsed={context.collapsed} maxHeight={contentHeight ? `${contentHeight}px` : undefined}>
            <ContentBase
                ref={contentRef}
                background={context.background}
                bordered={context.bordered}
                borderRadius={context.borderRadius}
                className={props.className}
                color={context.color}
                collapsed={context.collapsed}
            >
                {props.children}
            </ContentBase>
        </ContentAnimation>
    );
};

const ContentAnimation = styled.div<{collapsed: boolean; maxHeight?: string} & IProps>`
    height: ${(props) => (props.collapsed ? "0" : props.maxHeight || "auto")};
    transition: 0.3s ease-out;
    overflow: hidden;
`;

const ContentBase = styled.div<{background?: string; bordered: boolean; borderRadius?: string; collapsed: boolean; color?: string} & IProps>`
    background-color: ${(props) => props.background || "#fff"};
    padding: 1.6rem;
    border-radius: ${(props) => props.borderRadius || "0 0 1.6rem 1.6rem"};
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.secondary}` : "none")};
    border-top: none;
    color: ${(props) => props.color || "initial"};
`;
