import {omit} from "@pg-mono/nodash";

type Query = Record<string, string | string[]>;

export const validateQuery = (validQueryKeys: string[], initQuery: Query, updateQuery: Query): Query => {
    const validQuery = omit(initQuery, validQueryKeys);

    return {...validQuery, ...updateQuery};
};
import {omit} from "@pg-mono/nodash";

type Query = Record<string, string | string[]>;

export const validateQuery = (validQueryKeys: string[], initQuery: Query, updateQuery: Query): Query => {
    const validQuery = omit(initQuery, validQueryKeys);

    return {...validQuery, ...updateQuery};
};
