import React from "react";

import {IFaqQuestion} from "../interfaces/IFaq";

interface IProps {
    questions: IFaqQuestion[];
}

export const FaqSchema = (props: IProps) => {
    const json = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: props.questions.map((question) => ({
            "@type": "Question",
            name: question.name,
            acceptedAnswer: {
                "@type": "Answer",
                text: question.answer_text
            }
        }))
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
import React from "react";

import {IFaqQuestion} from "../interfaces/IFaq";

interface IProps {
    questions: IFaqQuestion[];
}

export const FaqSchema = (props: IProps) => {
    const json = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: props.questions.map((question) => ({
            "@type": "Question",
            name: question.name,
            acceptedAnswer: {
                "@type": "Answer",
                text: question.answer_text
            }
        }))
    };

    return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(json)}} />;
};
