import {CSSProperties, ElementType, forwardRef, PropsWithChildren} from "react";
import React from "react";
import cn from "classnames";

import * as styles from "./Row.module.css";

interface IProps extends React.HTMLAttributes<HTMLElement> {
    as?: ElementType;
    style?: CSSProperties;
}

export const Row = forwardRef((props: PropsWithChildren<IProps>, ref) => {
    const {as, style, className, ...restProps} = props;
    const Element = as || "div";

    return (
        <Element className={cn(styles.row, className)} style={style} ref={ref} {...restProps}>
            {props.children}
        </Element>
    );
});
import {CSSProperties, ElementType, forwardRef, PropsWithChildren} from "react";
import React from "react";
import cn from "classnames";

import * as styles from "./Row.module.css";

interface IProps extends React.HTMLAttributes<HTMLElement> {
    as?: ElementType;
    style?: CSSProperties;
}

export const Row = forwardRef((props: PropsWithChildren<IProps>, ref) => {
    const {as, style, className, ...restProps} = props;
    const Element = as || "div";

    return (
        <Element className={cn(styles.row, className)} style={style} ref={ref} {...restProps}>
            {props.children}
        </Element>
    );
});
