import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

export const pb = (size: Size = DEFAULT_SIZE) => `
    padding-bottom: ${calculateRemSize(size)};
`;

export const pt = (size: Size = DEFAULT_SIZE) => `
    padding-top: ${calculateRemSize(size)};
`;

export const pl = (size: Size = DEFAULT_SIZE) => `
    padding-left: ${calculateRemSize(size)};
`;

export const pr = (size: Size = DEFAULT_SIZE) => `
    padding-right: ${calculateRemSize(size)};
`;

export const pv = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return `
        padding-bottom: ${value};
        padding-top: ${value};
    `;
};

export const ph = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return `
        padding-left: ${value};
        padding-right: ${value};
    `;
};

// each size arg is multiplied by base 0.8rem. i.e p(2,2) = "padding: 1.6rem 1.6rem;". Default value is 1 (0.8rem)
export const p = (...sizeArgs: [] | [Size] | [Size, Size] | [Size, Size, Size] | [Size, Size, Size, Size]) => {
    if (sizeArgs.length > 0) {
        return `
            padding: ${sizeArgs.map((size) => calculateRemSize(size)).join(" ")};
        `;
    }

    return `
        padding: ${calculateRemSize(sizeArgs[0])};
    `;
};
import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

export const pb = (size: Size = DEFAULT_SIZE) => `
    padding-bottom: ${calculateRemSize(size)};
`;

export const pt = (size: Size = DEFAULT_SIZE) => `
    padding-top: ${calculateRemSize(size)};
`;

export const pl = (size: Size = DEFAULT_SIZE) => `
    padding-left: ${calculateRemSize(size)};
`;

export const pr = (size: Size = DEFAULT_SIZE) => `
    padding-right: ${calculateRemSize(size)};
`;

export const pv = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return `
        padding-bottom: ${value};
        padding-top: ${value};
    `;
};

export const ph = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return `
        padding-left: ${value};
        padding-right: ${value};
    `;
};

// each size arg is multiplied by base 0.8rem. i.e p(2,2) = "padding: 1.6rem 1.6rem;". Default value is 1 (0.8rem)
export const p = (...sizeArgs: [] | [Size] | [Size, Size] | [Size, Size, Size] | [Size, Size, Size, Size]) => {
    if (sizeArgs.length > 0) {
        return `
            padding: ${sizeArgs.map((size) => calculateRemSize(size)).join(" ")};
        `;
    }

    return `
        padding: ${calculateRemSize(sizeArgs[0])};
    `;
};
