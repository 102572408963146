import React from "react";
import classNames from "classnames";

import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text";

import {IBreadcrumb} from "./IBreadcrumb";
import {SeparatorIcon} from "./SeparatorIcon";

interface IProps extends IBreadcrumb {
    className?: string;
    separatorIcon?: JSX.Element;
    isLinkingToCurrentLocation?: boolean;
}

export const BreadcrumbsItem = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <li className={className} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            {props.isLinkingToCurrentLocation ? (
                <Text variant="info_txt_1" as="span">
                    <span itemProp="name">{props.name}</span>
                    <meta itemProp="position" content={props.position.toString()} />
                </Text>
            ) : (
                <Link size="small" href={props.url} target="_self" itemProp="item">
                    <span itemProp="name">{props.name}</span>
                    <meta itemProp="position" content={props.position.toString()} />
                </Link>
            )}
            {props.separatorIcon || <SeparatorIcon />}
        </li>
    );
};
import React from "react";
import classNames from "classnames";

import {Link} from "@pg-design/link-module";
import {Text} from "@pg-design/text";

import {IBreadcrumb} from "./IBreadcrumb";
import {SeparatorIcon} from "./SeparatorIcon";

interface IProps extends IBreadcrumb {
    className?: string;
    separatorIcon?: JSX.Element;
    isLinkingToCurrentLocation?: boolean;
}

export const BreadcrumbsItem = (props: IProps) => {
    const className = classNames(props.className);

    return (
        <li className={className} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            {props.isLinkingToCurrentLocation ? (
                <Text variant="info_txt_1" as="span">
                    <span itemProp="name">{props.name}</span>
                    <meta itemProp="position" content={props.position.toString()} />
                </Text>
            ) : (
                <Link size="small" href={props.url} target="_self" itemProp="item">
                    <span itemProp="name">{props.name}</span>
                    <meta itemProp="position" content={props.position.toString()} />
                </Link>
            )}
            {props.separatorIcon || <SeparatorIcon />}
        </li>
    );
};
