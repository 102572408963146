// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";
import fp from "facepaint";

import {getBreakpoints} from "../utils/get_breakpoints";
import {getGridValuesFor} from "../utils/get_grid_values_for";

interface IProps {
    fluid?: boolean;
}

export const Container = styled.div<IProps>`
    margin-right: auto;
    margin-left: auto;

    ${({fluid, theme: {grid}}) =>
        fluid
            ? {maxWidth: "100%"}
            : fp(getBreakpoints(grid))({
                  paddingLeft: [8, ...getGridValuesFor("offset", grid)],
                  paddingRight: [8, ...getGridValuesFor("offset", grid)],
                  maxWidth: [360, ...getGridValuesFor("width", grid)]
              })}
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import styled from "@emotion/styled";
import fp from "facepaint";

import {getBreakpoints} from "../utils/get_breakpoints";
import {getGridValuesFor} from "../utils/get_grid_values_for";

interface IProps {
    fluid?: boolean;
}

export const Container = styled.div<IProps>`
    margin-right: auto;
    margin-left: auto;

    ${({fluid, theme: {grid}}) =>
        fluid
            ? {maxWidth: "100%"}
            : fp(getBreakpoints(grid))({
                  paddingLeft: [8, ...getGridValuesFor("offset", grid)],
                  paddingRight: [8, ...getGridValuesFor("offset", grid)],
                  maxWidth: [360, ...getGridValuesFor("width", grid)]
              })}
`;
