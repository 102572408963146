import React from "react";
import {css, useTheme} from "@emotion/react";

import {Accordion} from "@pg-design/accordion";
import {Text} from "@pg-design/text-module";

import {IFaqQuestion} from "../interfaces/IFaq";

interface IProps extends IFaqQuestion {}

export const FaqQuestion = (props: IProps) => {
    const theme = useTheme();

    return (
        <Accordion>
            <Accordion.Header>
                <Text as="h2" variant="headline_6">
                    {props.name}
                </Text>
            </Accordion.Header>
            <Accordion.Content>
                <Text as="div" variant="body_copy_2" color={theme.colors.gray[700]} css={[contentStyle]}>
                    <div dangerouslySetInnerHTML={{__html: props.answer}}></div>
                </Text>
            </Accordion.Content>
        </Accordion>
    );
};

const contentStyle = css`
    a {
        text-decoration: underline;
    }
`;
import React from "react";
import {css, useTheme} from "@emotion/react";

import {Accordion} from "@pg-design/accordion";
import {Text} from "@pg-design/text-module";

import {IFaqQuestion} from "../interfaces/IFaq";

interface IProps extends IFaqQuestion {}

export const FaqQuestion = (props: IProps) => {
    const theme = useTheme();

    return (
        <Accordion>
            <Accordion.Header>
                <Text as="h2" variant="headline_6">
                    {props.name}
                </Text>
            </Accordion.Header>
            <Accordion.Content>
                <Text as="div" variant="body_copy_2" color={theme.colors.gray[700]} css={[contentStyle]}>
                    <div dangerouslySetInnerHTML={{__html: props.answer}}></div>
                </Text>
            </Accordion.Content>
        </Accordion>
    );
};

const contentStyle = css`
    a {
        text-decoration: underline;
    }
`;
