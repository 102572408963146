import React from "react";
import {SyntheticEvent} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Text} from "@pg-design/text";
import {RequestState} from "@pg-mono/request-state";

import {getPaginationSeparatorVariantStyle, getPaginationVariantStyle, getTextVariant, PaginationSize} from "./variants_utils";

interface IProps {
    className?: string;
    iconPrev: JSX.Element;
    iconNext: JSX.Element;
    requestState?: RequestState;
    onChangePageClick?: (page: number) => void;
    locationPathname?: string;
    hrefBuilder?: (page: number) => string;
    pageCount: number;
    currentPage: number;
    multiNumbersInside?: boolean;
    showExtraPages?: boolean;
    hideNumbersOnMobile?: boolean;
    hideNumbers?: boolean;
    size?: PaginationSize;
}

interface IState {
    currentPage: number;
}

export class PaginationWithList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentPage: this.props.currentPage
        };
    }

    private textVariant = getTextVariant(this.props.size);

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.requestState === RequestState.Waiting && this.props.requestState === RequestState.Success) {
            return this.setState({currentPage: this.props.currentPage});
        } else if (prevProps.currentPage !== this.props.currentPage && prevProps.locationPathname === this.props.locationPathname) {
            return this.setState({currentPage: this.props.currentPage});
        }
    }

    private renderPrevButton = () => {
        const prevPageNumber = this.props.currentPage - 1 || 1;
        const prevPageLink = this.props.hrefBuilder && this.props.hrefBuilder(prevPageNumber);

        // const isDisabled = this.props.currentPage <= 1 || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage <= 1;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(prevPageNumber);
                e.preventDefault();
            }
        };

        return (
            <Circle css={prev} onClick={onClick} href={prevPageLink} rel="prev" data-testid="prev-page-link" isDisabled={isDisabled} size={this.props.size}>
                {this.props.iconPrev}
            </Circle>
        );
    };

    private renderNextButton = () => {
        const nextPageNumber = this.props.currentPage + 1;
        const nextPageLink = this.props.hrefBuilder && this.props.hrefBuilder(nextPageNumber);

        // const isDisabled = this.props.currentPage >= this.props.pageCount || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage >= this.props.pageCount;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(nextPageNumber);
                e.preventDefault();
            }
        };

        return (
            <Circle
                css={next}
                onClick={onClick}
                href={isDisabled ? "" : nextPageLink}
                rel="next"
                data-testid="next-page-link"
                isDisabled={isDisabled}
                size={this.props.size}
            >
                {this.props.iconNext}
            </Circle>
        );
    };

    private renderPageButton = (value: number, current: number) => {
        const url = this.props.hrefBuilder && this.props.hrefBuilder(value);
        const isCurrentPage = value === current;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(value);
                e.preventDefault();
            }
        };

        return (
            <li>
                <Circle onClick={onClick} href={url} data-testid={`page-${value}-link`} isCurrentPage={isCurrentPage} size={this.props.size}>
                    <Text as="span" variant={this.textVariant}>
                        {value}
                    </Text>
                </Circle>
            </li>
        );
    };

    private renderPageSeparator = () => {
        return (
            <li css={getPaginationSeparatorVariantStyle(this.props.size)}>
                <Text as="span" data-testid="pagination-separator" variant="headline_6">
                    ...
                </Text>
            </li>
        );
    };

    private generatePages = (pages: number[], current: number) => {
        const itemsToShow = 3;

        const last = pages.length;
        let bottomRange = current - 4 >= 0 ? current - 4 : 0;

        let topRange = current < 3 ? 3 : current + 1;

        if (topRange + 2 >= last) {
            topRange = topRange + 2;
            bottomRange = last >= 7 ? last - 7 : 0;
        }
        const pagesRange = pages.slice(bottomRange, this.props.showExtraPages ? topRange + 2 : topRange);

        const pagesArr: React.ReactElement[] = [];
        pagesArr.push(this.renderPageButton(1, current));

        const hasBottomRangeSeparator = last > 7 && current > 4;
        const hasTopRangeSeparator = last > 7 && current + 3 < last;

        if (hasBottomRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            pagesRange.slice(this.props.showExtraPages ? 0 : 2).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else if (current + itemsToShow - 1 < last) {
            pagesRange.slice(1, topRange).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else {
            pagesRange.slice(1).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        }
        if (hasTopRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            pagesArr.push(this.renderPageButton(last, current));
        }
        return pagesArr.map((element: JSX.Element, index: number) => <React.Fragment key={index}>{element}</React.Fragment>);
    };

    private renderPageCount = (pageCount: number) => {
        if (this.props.multiNumbersInside) {
            return this.generatePages(
                Array.from({length: pageCount}, (_, p: number) => p + 1),
                this.state.currentPage
            );
        }
        return `${this.state.currentPage} z ${pageCount}`;
    };

    public render() {
        return this.props.pageCount > 1 ? (
            <PaginationHolder className={this.props.className} pageCount={this.props.pageCount} currentPage={this.state.currentPage}>
                {this.renderPrevButton()}
                <PaginationNumbersHolder hideNumbersOnMobile={this.props.hideNumbersOnMobile} hideNumbers={this.props.hideNumbers}>
                    {this.renderPageCount(this.props.pageCount)}
                </PaginationNumbersHolder>
                {this.renderNextButton()}
            </PaginationHolder>
        ) : null;
    }
}

interface ITheme {
    breakpoints: {
        screen_sm: string;
        screen_xs: string;
    };
    fonts: {
        font_size_small: string;
    };
    pagination: {
        border_radius: string;
        border: string;
        color: string;
        icons_color: string;
        background_color: string;
        background_color_hover: string;
        number_background_color: string;
    };
}

interface IPaginationHolder {
    theme?: ITheme;
    pageCount: number;
    currentPage: number;
}

interface IPaginationNumbersHolder {
    theme?: ITheme;
    hideNumbersOnMobile?: boolean;
    hideNumbers?: boolean;
}

const PaginationHolder = styled.nav<IPaginationHolder>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    @media (min-width: ${(props) => props.theme?.breakpoints?.screen_sm ?? "1024px"}) {
        flex-wrap: nowrap;
    }

    @media (max-width: 375px) {
        ${(props) =>
            props.pageCount === 7 &&
            css`
                > ul > li {
                    margin-right: 0.4rem !important;
                }
            `}
    }
`;

const Circle = styled.a<{isCurrentPage?: boolean; isDisabled?: boolean; size?: PaginationSize}>`
    ${(props) => getPaginationVariantStyle(props.size)};

    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 1rem;
    font-weight: 500;

    cursor: pointer;

    border-radius: 50%;
    background-color: ${(props) => (props.isCurrentPage ? props.theme.colors.primary : `#fff`)};
    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        `};

    &:hover {
        & > svg {
            fill: #d7afe1;
        }
    }
`;

const next = () => css`
    display: flex;
    order: 3;
    margin-right: 0;
`;

const prev = () => css`
    display: flex;
    order: 1;
`;

const PaginationNumbersHolder = styled.ul<IPaginationNumbersHolder>`
    display: ${(props) => (props.hideNumbersOnMobile || props.hideNumbers ? `none` : `flex`)};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    order: 2;
    flex-grow: 0;
    flex-basis: auto;
    list-style: none;
    padding-left: 0;
    margin: 0;

    @media (min-width: 1024px) {
        display: ${(props) => (props.hideNumbers ? `none` : `flex`)};
        margin: 0.5rem 0;
    }
`;
import React from "react";
import {SyntheticEvent} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Text} from "@pg-design/text";
import {RequestState} from "@pg-mono/request-state";

import {getPaginationSeparatorVariantStyle, getPaginationVariantStyle, getTextVariant, PaginationSize} from "./variants_utils";

interface IProps {
    className?: string;
    iconPrev: JSX.Element;
    iconNext: JSX.Element;
    requestState?: RequestState;
    onChangePageClick?: (page: number) => void;
    locationPathname?: string;
    hrefBuilder?: (page: number) => string;
    pageCount: number;
    currentPage: number;
    multiNumbersInside?: boolean;
    showExtraPages?: boolean;
    hideNumbersOnMobile?: boolean;
    hideNumbers?: boolean;
    size?: PaginationSize;
}

interface IState {
    currentPage: number;
}

export class PaginationWithList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentPage: this.props.currentPage
        };
    }

    private textVariant = getTextVariant(this.props.size);

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.requestState === RequestState.Waiting && this.props.requestState === RequestState.Success) {
            return this.setState({currentPage: this.props.currentPage});
        } else if (prevProps.currentPage !== this.props.currentPage && prevProps.locationPathname === this.props.locationPathname) {
            return this.setState({currentPage: this.props.currentPage});
        }
    }

    private renderPrevButton = () => {
        const prevPageNumber = this.props.currentPage - 1 || 1;
        const prevPageLink = this.props.hrefBuilder && this.props.hrefBuilder(prevPageNumber);

        // const isDisabled = this.props.currentPage <= 1 || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage <= 1;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(prevPageNumber);
                e.preventDefault();
            }
        };

        return (
            <Circle css={prev} onClick={onClick} href={prevPageLink} rel="prev" data-testid="prev-page-link" isDisabled={isDisabled} size={this.props.size}>
                {this.props.iconPrev}
            </Circle>
        );
    };

    private renderNextButton = () => {
        const nextPageNumber = this.props.currentPage + 1;
        const nextPageLink = this.props.hrefBuilder && this.props.hrefBuilder(nextPageNumber);

        // const isDisabled = this.props.currentPage >= this.props.pageCount || this.props.requestState !== RequestState.Success;
        const isDisabled = this.props.currentPage >= this.props.pageCount;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(nextPageNumber);
                e.preventDefault();
            }
        };

        return (
            <Circle
                css={next}
                onClick={onClick}
                href={isDisabled ? "" : nextPageLink}
                rel="next"
                data-testid="next-page-link"
                isDisabled={isDisabled}
                size={this.props.size}
            >
                {this.props.iconNext}
            </Circle>
        );
    };

    private renderPageButton = (value: number, current: number) => {
        const url = this.props.hrefBuilder && this.props.hrefBuilder(value);
        const isCurrentPage = value === current;

        const onClick = (e: SyntheticEvent) => {
            if (this.props.onChangePageClick) {
                this.props.onChangePageClick(value);
                e.preventDefault();
            }
        };

        return (
            <li>
                <Circle onClick={onClick} href={url} data-testid={`page-${value}-link`} isCurrentPage={isCurrentPage} size={this.props.size}>
                    <Text as="span" variant={this.textVariant}>
                        {value}
                    </Text>
                </Circle>
            </li>
        );
    };

    private renderPageSeparator = () => {
        return (
            <li css={getPaginationSeparatorVariantStyle(this.props.size)}>
                <Text as="span" data-testid="pagination-separator" variant="headline_6">
                    ...
                </Text>
            </li>
        );
    };

    private generatePages = (pages: number[], current: number) => {
        const itemsToShow = 3;

        const last = pages.length;
        let bottomRange = current - 4 >= 0 ? current - 4 : 0;

        let topRange = current < 3 ? 3 : current + 1;

        if (topRange + 2 >= last) {
            topRange = topRange + 2;
            bottomRange = last >= 7 ? last - 7 : 0;
        }
        const pagesRange = pages.slice(bottomRange, this.props.showExtraPages ? topRange + 2 : topRange);

        const pagesArr: React.ReactElement[] = [];
        pagesArr.push(this.renderPageButton(1, current));

        const hasBottomRangeSeparator = last > 7 && current > 4;
        const hasTopRangeSeparator = last > 7 && current + 3 < last;

        if (hasBottomRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            pagesRange.slice(this.props.showExtraPages ? 0 : 2).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else if (current + itemsToShow - 1 < last) {
            pagesRange.slice(1, topRange).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        } else {
            pagesRange.slice(1).forEach((page: number) => {
                pagesArr.push(this.renderPageButton(page, current));
            });
        }
        if (hasTopRangeSeparator) {
            pagesArr.push(this.renderPageSeparator());
            pagesArr.push(this.renderPageButton(last, current));
        }
        return pagesArr.map((element: JSX.Element, index: number) => <React.Fragment key={index}>{element}</React.Fragment>);
    };

    private renderPageCount = (pageCount: number) => {
        if (this.props.multiNumbersInside) {
            return this.generatePages(
                Array.from({length: pageCount}, (_, p: number) => p + 1),
                this.state.currentPage
            );
        }
        return `${this.state.currentPage} z ${pageCount}`;
    };

    public render() {
        return this.props.pageCount > 1 ? (
            <PaginationHolder className={this.props.className} pageCount={this.props.pageCount} currentPage={this.state.currentPage}>
                {this.renderPrevButton()}
                <PaginationNumbersHolder hideNumbersOnMobile={this.props.hideNumbersOnMobile} hideNumbers={this.props.hideNumbers}>
                    {this.renderPageCount(this.props.pageCount)}
                </PaginationNumbersHolder>
                {this.renderNextButton()}
            </PaginationHolder>
        ) : null;
    }
}

interface ITheme {
    breakpoints: {
        screen_sm: string;
        screen_xs: string;
    };
    fonts: {
        font_size_small: string;
    };
    pagination: {
        border_radius: string;
        border: string;
        color: string;
        icons_color: string;
        background_color: string;
        background_color_hover: string;
        number_background_color: string;
    };
}

interface IPaginationHolder {
    theme?: ITheme;
    pageCount: number;
    currentPage: number;
}

interface IPaginationNumbersHolder {
    theme?: ITheme;
    hideNumbersOnMobile?: boolean;
    hideNumbers?: boolean;
}

const PaginationHolder = styled.nav<IPaginationHolder>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    @media (min-width: ${(props) => props.theme?.breakpoints?.screen_sm ?? "1024px"}) {
        flex-wrap: nowrap;
    }

    @media (max-width: 375px) {
        ${(props) =>
            props.pageCount === 7 &&
            css`
                > ul > li {
                    margin-right: 0.4rem !important;
                }
            `}
    }
`;

const Circle = styled.a<{isCurrentPage?: boolean; isDisabled?: boolean; size?: PaginationSize}>`
    ${(props) => getPaginationVariantStyle(props.size)};

    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 1rem;
    font-weight: 500;

    cursor: pointer;

    border-radius: 50%;
    background-color: ${(props) => (props.isCurrentPage ? props.theme.colors.primary : `#fff`)};
    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        `};

    &:hover {
        & > svg {
            fill: #d7afe1;
        }
    }
`;

const next = () => css`
    display: flex;
    order: 3;
    margin-right: 0;
`;

const prev = () => css`
    display: flex;
    order: 1;
`;

const PaginationNumbersHolder = styled.ul<IPaginationNumbersHolder>`
    display: ${(props) => (props.hideNumbersOnMobile || props.hideNumbers ? `none` : `flex`)};
    flex-direction: row;
    align-items: center;
    justify-content: center;
    order: 2;
    flex-grow: 0;
    flex-basis: auto;
    list-style: none;
    padding-left: 0;
    margin: 0;

    @media (min-width: 1024px) {
        display: ${(props) => (props.hideNumbers ? `none` : `flex`)};
        margin: 0.5rem 0;
    }
`;
