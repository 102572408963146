import {Theme} from "@emotion/react";

import {IBreakpoint} from "../interfaces/IBreakpoint";

export const getGridValuesFor = (field: keyof IBreakpoint, grid: Theme["grid"]) => {
    return Object.values(grid).map((breakpoint) => breakpoint[field]);
};
import {Theme} from "@emotion/react";

import {IBreakpoint} from "../interfaces/IBreakpoint";

export const getGridValuesFor = (field: keyof IBreakpoint, grid: Theme["grid"]) => {
    return Object.values(grid).map((breakpoint) => breakpoint[field]);
};
