import React, {useCallback, useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import classNames from "classnames";

import {SeparatorIcon} from "./SeparatorIcon";

export interface IBreadCrumbsProps {
    children?: React.ReactNode;
    className?: string;
    homePageUrl: string;
    homePageIcon?: JSX.Element;
    separatorIcon?: JSX.Element;
}

export const Breadcrumbs = (props: IBreadCrumbsProps) => {
    const listRef = useRef<HTMLOListElement | null>(null);
    const [isOverlayVisible, setOverlay] = useState<boolean>(false);

    const className = classNames(props.className);
    const resizeHandler = useCallback(() => {
        listRef.current && (listRef.current.scrollLeft += 50);
        listRef.current && listRef.current.scrollLeft > 0 ? setOverlay(true) : setOverlay(false);
    }, []);

    useEffect(() => {
        listRef.current && (listRef.current.scrollLeft += 2000);
        listRef.current && listRef.current.scrollLeft > 0 ? setOverlay(true) : setOverlay(false);
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);
    return (
        <nav css={[breadcrumbsNav]} className={className} id="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
            <ol css={breadcrumbsList} id="breadcrumbs-list" ref={listRef}>
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <a href={props.homePageUrl} itemProp="item">
                        <span css={[hideText]} itemProp="name">
                            Strona główna
                        </span>

                        {props.homePageIcon ? props.homePageIcon : defaultHomePageIcon}
                    </a>

                    <SeparatorIcon />

                    <meta itemProp="position" content="1" />
                </li>

                {props.children}
            </ol>

            {isOverlayVisible && <span css={[bgOverlay]} id="breadcrumbs-overlay" />}
        </nav>
    );
};

const breadcrumbsList = (theme: Theme) => css`
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    font-size: 1.244rem;
    font-weight: 500;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 0;

    > li {
        color: ${theme.colors?.gray[700] ?? "#909090"};
        fill: ${theme.colors?.gray[700] ?? "#909090"};
        display: inline-block;
        white-space: nowrap;

        > a {
            color: inherit;
            fill: ${theme.colors?.gray[700] ?? "#909090"};
        }

        &:last-of-type {
            color: ${theme.colors?.gray[800] ?? "#43444d"};
            fill: ${theme.colors?.gray[800] ?? "#43444d"};

            > a {
                color: inherit;
                fill: ${theme.colors?.secondary ?? "#43444d"};
            }

            > i {
                visibility: hidden;
            }
        }
    }
`;

const breadcrumbsNav = css`
    position: relative;
`;

const hideText = css`
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
`;

const bgOverlay = css`
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
    left: 0;
    position: absolute;
    top: 0;
    width: 4rem;
    bottom: 0;
    display: block;
`;

const defaultHomePageIcon = (
    <svg width={12} height={12} viewBox="0 0 10 10">
        <path d="M9.83,4.91,5.81.53a1.18,1.18,0,0,0-1.62,0l-4,4.38a.66.66,0,0,0,0,.92A.68.68,0,0,0,.68,6a.66.66,0,0,0,.45-.21l.15-.16L1.55,8.8a1.09,1.09,0,0,0,1.09,1H7.36a1.1,1.1,0,0,0,1.09-1l.27-3.17.15.16a.68.68,0,0,0,.92,0A.66.66,0,0,0,9.83,4.91ZM7.66,4.29l-.37,4.4H2.71l-.37-4.4L5,1.39Z" />
    </svg>
);
import React, {useCallback, useEffect, useRef, useState} from "react";
import {css, Theme} from "@emotion/react";
import classNames from "classnames";

import {SeparatorIcon} from "./SeparatorIcon";

export interface IBreadCrumbsProps {
    children?: React.ReactNode;
    className?: string;
    homePageUrl: string;
    homePageIcon?: JSX.Element;
    separatorIcon?: JSX.Element;
}

export const Breadcrumbs = (props: IBreadCrumbsProps) => {
    const listRef = useRef<HTMLOListElement | null>(null);
    const [isOverlayVisible, setOverlay] = useState<boolean>(false);

    const className = classNames(props.className);
    const resizeHandler = useCallback(() => {
        listRef.current && (listRef.current.scrollLeft += 50);
        listRef.current && listRef.current.scrollLeft > 0 ? setOverlay(true) : setOverlay(false);
    }, []);

    useEffect(() => {
        listRef.current && (listRef.current.scrollLeft += 2000);
        listRef.current && listRef.current.scrollLeft > 0 ? setOverlay(true) : setOverlay(false);
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);
    return (
        <nav css={[breadcrumbsNav]} className={className} id="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
            <ol css={breadcrumbsList} id="breadcrumbs-list" ref={listRef}>
                <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <a href={props.homePageUrl} itemProp="item">
                        <span css={[hideText]} itemProp="name">
                            Strona główna
                        </span>

                        {props.homePageIcon ? props.homePageIcon : defaultHomePageIcon}
                    </a>

                    <SeparatorIcon />

                    <meta itemProp="position" content="1" />
                </li>

                {props.children}
            </ol>

            {isOverlayVisible && <span css={[bgOverlay]} id="breadcrumbs-overlay" />}
        </nav>
    );
};

const breadcrumbsList = (theme: Theme) => css`
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    font-size: 1.244rem;
    font-weight: 500;
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
    z-index: 0;

    > li {
        color: ${theme.colors?.gray[700] ?? "#909090"};
        fill: ${theme.colors?.gray[700] ?? "#909090"};
        display: inline-block;
        white-space: nowrap;

        > a {
            color: inherit;
            fill: ${theme.colors?.gray[700] ?? "#909090"};
        }

        &:last-of-type {
            color: ${theme.colors?.gray[800] ?? "#43444d"};
            fill: ${theme.colors?.gray[800] ?? "#43444d"};

            > a {
                color: inherit;
                fill: ${theme.colors?.secondary ?? "#43444d"};
            }

            > i {
                visibility: hidden;
            }
        }
    }
`;

const breadcrumbsNav = css`
    position: relative;
`;

const hideText = css`
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
`;

const bgOverlay = css`
    background-image: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-repeat: repeat-x;
    left: 0;
    position: absolute;
    top: 0;
    width: 4rem;
    bottom: 0;
    display: block;
`;

const defaultHomePageIcon = (
    <svg width={12} height={12} viewBox="0 0 10 10">
        <path d="M9.83,4.91,5.81.53a1.18,1.18,0,0,0-1.62,0l-4,4.38a.66.66,0,0,0,0,.92A.68.68,0,0,0,.68,6a.66.66,0,0,0,.45-.21l.15-.16L1.55,8.8a1.09,1.09,0,0,0,1.09,1H7.36a1.1,1.1,0,0,0,1.09-1l.27-3.17.15.16a.68.68,0,0,0,.92,0A.66.66,0,0,0,9.83,4.91ZM7.66,4.29l-.37,4.4H2.71l-.37-4.4L5,1.39Z" />
    </svg>
);
