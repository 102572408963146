import {css} from "@emotion/react";

import {TextVariant} from "@pg-design/text";

export const enum PaginationSize {
    XS = "xs",
    SM = "sm",
    LG = "lg"
}

// note: this is not a perfect place for SVG transformations - they should be moved
export const getPaginationVariantStyle = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return css`
                margin: 0 0.8rem 0 0;

                width: 4.8rem;
                height: 4.8rem;

                > svg {
                    transform: scale(1.8);
                }
            `;
        case PaginationSize.XS:
            return css`
                margin: 0 1.6rem 0 0;

                width: 3.2rem;
                height: 3.2rem;

                > svg {
                    transform: scale(1.4);
                }
            `;
        case PaginationSize.LG:
        default:
            return css`
                margin: 0 1.6rem 0 0;

                width: 6.4rem;
                height: 6.4rem;

                > svg {
                    transform: scale(2.4);
                }
            `;
    }
};

export const getPaginationSeparatorVariantStyle = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return css`
                margin: 0 0.8rem 0 0;
            `;
        case PaginationSize.XS:
            return css`
                margin: 0 1.6rem 0 0;
            `;
        case PaginationSize.LG:
        default:
            return css`
                margin: 0 1.6rem 0 0;
            `;
    }
};

export const getTextVariant = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return TextVariant.HEADLINE_6;
        case PaginationSize.XS:
            return TextVariant.INFO_TXT_1;
        case PaginationSize.LG:
        default:
            return TextVariant.HEADLINE_4;
    }
};
import {css} from "@emotion/react";

import {TextVariant} from "@pg-design/text";

export const enum PaginationSize {
    XS = "xs",
    SM = "sm",
    LG = "lg"
}

// note: this is not a perfect place for SVG transformations - they should be moved
export const getPaginationVariantStyle = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return css`
                margin: 0 0.8rem 0 0;

                width: 4.8rem;
                height: 4.8rem;

                > svg {
                    transform: scale(1.8);
                }
            `;
        case PaginationSize.XS:
            return css`
                margin: 0 1.6rem 0 0;

                width: 3.2rem;
                height: 3.2rem;

                > svg {
                    transform: scale(1.4);
                }
            `;
        case PaginationSize.LG:
        default:
            return css`
                margin: 0 1.6rem 0 0;

                width: 6.4rem;
                height: 6.4rem;

                > svg {
                    transform: scale(2.4);
                }
            `;
    }
};

export const getPaginationSeparatorVariantStyle = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return css`
                margin: 0 0.8rem 0 0;
            `;
        case PaginationSize.XS:
            return css`
                margin: 0 1.6rem 0 0;
            `;
        case PaginationSize.LG:
        default:
            return css`
                margin: 0 1.6rem 0 0;
            `;
    }
};

export const getTextVariant = (size?: PaginationSize) => {
    switch (size) {
        case PaginationSize.SM:
            return TextVariant.HEADLINE_6;
        case PaginationSize.XS:
            return TextVariant.INFO_TXT_1;
        case PaginationSize.LG:
        default:
            return TextVariant.HEADLINE_4;
    }
};
