import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandClockIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.628 5.682a61.84 61.84 0 0 0-.008-2.246c-.004-.183-.012-.366-.019-.55l-.015-.422c0-.028 0-.06.002-.096.005-.12.011-.269-.067-.336-.051-.044-.107-.044-.158 0-.077.065-.072.204-.068.319.001.033.002.064.001.09a53.23 53.23 0 0 1-.018.44c-.008.185-.016.37-.021.555-.025.804-.021 1.61-.01 2.415a.185.185 0 0 0 .18.193c.535.15 1.073.292 1.613.42.15.036.302.067.454.098l.238.05c.032.006.067.017.103.027.095.028.2.058.283.039.094-.022.133-.167.059-.235-.066-.06-.17-.086-.265-.11a1.512 1.512 0 0 1-.1-.026l-.225-.074c-.149-.05-.298-.1-.45-.144-.5-.148-1.003-.283-1.51-.407Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.238 8.31C1.608 7.823 1.075 7.187.8 6.405a.217.217 0 0 1-.023-.046A10.275 10.275 0 0 0 .73 6.22c-.036-.102-.072-.204-.1-.31a3.435 3.435 0 0 1-.085-.434A4.268 4.268 0 0 1 .629 3.81c.122-.472.33-.92.6-1.316.244-.363.533-.69.856-.976.288-.256.605-.5.947-.671C3.999.362 5.17.454 6.185.71c.426.107.831.242 1.237.474a3.77 3.77 0 0 1 1.069.92c.618.775.95 1.76 1.003 2.766a5.538 5.538 0 0 1-.75 3.06 3.031 3.031 0 0 1-.796.898 3.24 3.24 0 0 1-1.02.502c-.782.233-1.602.213-2.415.035A5.855 5.855 0 0 1 2.238 8.31Zm-1.14-2.046a.146.146 0 0 1 .008.039 2.731 2.731 0 0 0 .2.442 3.635 3.635 0 0 0 .23.37l.004.004.01.013.01.014.012.016.029.038a4.17 4.17 0 0 0 .601.632l.042.035.018.015.011.01.012.01.014.011a5.132 5.132 0 0 0 1.173.712l.006.002.018.008.042.018.025.01a5.235 5.235 0 0 0 .418.158 5.976 5.976 0 0 0 .876.221l.01.002a3.635 3.635 0 0 0 .25.035 4.78 4.78 0 0 0 1.155-.006l.01-.002.016-.002-.015.002.035-.005.065-.01a3.318 3.318 0 0 0 .397-.09 3.574 3.574 0 0 0 .475-.175 2.95 2.95 0 0 0 .57-.353l.013-.011.014-.011.042-.037a2.783 2.783 0 0 0 .365-.396v-.001l.005-.007a.521.521 0 0 1 .01-.014l.011-.015.037-.055a5.295 5.295 0 0 0 .428-.823l.02-.051.01-.024.021-.057.01-.029a5.533 5.533 0 0 0 .243-.905 5.608 5.608 0 0 0 .046-.338 5.27 5.27 0 0 0-.026-1.25A4.868 4.868 0 0 0 8.8 3.39l-.002-.006-.003-.007-.009-.021a.947.947 0 0 1-.009-.021l-.007-.018a4.205 4.205 0 0 0-.465-.828l-.005-.007a1.543 1.543 0 0 0-.01-.013l-.049-.064a3.713 3.713 0 0 0-.461-.49l-.037-.032-.037-.031a3.757 3.757 0 0 0-.756-.47l-.005-.003-.006-.003-.031-.013-.051-.021A6.38 6.38 0 0 0 5.416.96a5.81 5.81 0 0 0-.76-.056 3.96 3.96 0 0 0-.741.062 2.808 2.808 0 0 0-1.15.504 5.468 5.468 0 0 0-.254.19l-.019.016-.005.004-.02.015a5.135 5.135 0 0 0-.827.87l-.01.016a.476.476 0 0 1-.014.02l-.006.008-.049.07a4.333 4.333 0 0 0-.404.738l-.01.023a3.852 3.852 0 0 0-.15.448 3.856 3.856 0 0 0-.1.51 4.01 4.01 0 0 0 .009.973c.043.297.098.61.192.893Z"
            />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandClockIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.628 5.682a61.84 61.84 0 0 0-.008-2.246c-.004-.183-.012-.366-.019-.55l-.015-.422c0-.028 0-.06.002-.096.005-.12.011-.269-.067-.336-.051-.044-.107-.044-.158 0-.077.065-.072.204-.068.319.001.033.002.064.001.09a53.23 53.23 0 0 1-.018.44c-.008.185-.016.37-.021.555-.025.804-.021 1.61-.01 2.415a.185.185 0 0 0 .18.193c.535.15 1.073.292 1.613.42.15.036.302.067.454.098l.238.05c.032.006.067.017.103.027.095.028.2.058.283.039.094-.022.133-.167.059-.235-.066-.06-.17-.086-.265-.11a1.512 1.512 0 0 1-.1-.026l-.225-.074c-.149-.05-.298-.1-.45-.144-.5-.148-1.003-.283-1.51-.407Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.238 8.31C1.608 7.823 1.075 7.187.8 6.405a.217.217 0 0 1-.023-.046A10.275 10.275 0 0 0 .73 6.22c-.036-.102-.072-.204-.1-.31a3.435 3.435 0 0 1-.085-.434A4.268 4.268 0 0 1 .629 3.81c.122-.472.33-.92.6-1.316.244-.363.533-.69.856-.976.288-.256.605-.5.947-.671C3.999.362 5.17.454 6.185.71c.426.107.831.242 1.237.474a3.77 3.77 0 0 1 1.069.92c.618.775.95 1.76 1.003 2.766a5.538 5.538 0 0 1-.75 3.06 3.031 3.031 0 0 1-.796.898 3.24 3.24 0 0 1-1.02.502c-.782.233-1.602.213-2.415.035A5.855 5.855 0 0 1 2.238 8.31Zm-1.14-2.046a.146.146 0 0 1 .008.039 2.731 2.731 0 0 0 .2.442 3.635 3.635 0 0 0 .23.37l.004.004.01.013.01.014.012.016.029.038a4.17 4.17 0 0 0 .601.632l.042.035.018.015.011.01.012.01.014.011a5.132 5.132 0 0 0 1.173.712l.006.002.018.008.042.018.025.01a5.235 5.235 0 0 0 .418.158 5.976 5.976 0 0 0 .876.221l.01.002a3.635 3.635 0 0 0 .25.035 4.78 4.78 0 0 0 1.155-.006l.01-.002.016-.002-.015.002.035-.005.065-.01a3.318 3.318 0 0 0 .397-.09 3.574 3.574 0 0 0 .475-.175 2.95 2.95 0 0 0 .57-.353l.013-.011.014-.011.042-.037a2.783 2.783 0 0 0 .365-.396v-.001l.005-.007a.521.521 0 0 1 .01-.014l.011-.015.037-.055a5.295 5.295 0 0 0 .428-.823l.02-.051.01-.024.021-.057.01-.029a5.533 5.533 0 0 0 .243-.905 5.608 5.608 0 0 0 .046-.338 5.27 5.27 0 0 0-.026-1.25A4.868 4.868 0 0 0 8.8 3.39l-.002-.006-.003-.007-.009-.021a.947.947 0 0 1-.009-.021l-.007-.018a4.205 4.205 0 0 0-.465-.828l-.005-.007a1.543 1.543 0 0 0-.01-.013l-.049-.064a3.713 3.713 0 0 0-.461-.49l-.037-.032-.037-.031a3.757 3.757 0 0 0-.756-.47l-.005-.003-.006-.003-.031-.013-.051-.021A6.38 6.38 0 0 0 5.416.96a5.81 5.81 0 0 0-.76-.056 3.96 3.96 0 0 0-.741.062 2.808 2.808 0 0 0-1.15.504 5.468 5.468 0 0 0-.254.19l-.019.016-.005.004-.02.015a5.135 5.135 0 0 0-.827.87l-.01.016a.476.476 0 0 1-.014.02l-.006.008-.049.07a4.333 4.333 0 0 0-.404.738l-.01.023a3.852 3.852 0 0 0-.15.448 3.856 3.856 0 0 0-.1.51 4.01 4.01 0 0 0 .009.973c.043.297.098.61.192.893Z"
            />
        </SvgIcon>
    );
};
