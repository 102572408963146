import React from "react";
import styled from "@emotion/styled";

interface IProps {
    separatorIcon?: JSX.Element;
}

export const SeparatorIcon = (props: IProps) => {
    return <SeparatorIconBase>{props.separatorIcon ? props.separatorIcon : separatorIcon}</SeparatorIconBase>;
};

const separatorIcon = (
    <svg width={9} height={9} viewBox="0 0 10 10">
        <path d="M2.39,1.29,6.1,5,2.39,8.72a.75.75,0,0,0,0,1.06.77.77,0,0,0,1.07,0L7.54,5.71a1,1,0,0,0,0-1.41L3.46.22a.77.77,0,0,0-1.07,0A.77.77,0,0,0,2.39,1.29Z" />
    </svg>
);

const SeparatorIconBase = styled.i`
    padding: 0 1rem;
`;
import React from "react";
import styled from "@emotion/styled";

interface IProps {
    separatorIcon?: JSX.Element;
}

export const SeparatorIcon = (props: IProps) => {
    return <SeparatorIconBase>{props.separatorIcon ? props.separatorIcon : separatorIcon}</SeparatorIconBase>;
};

const separatorIcon = (
    <svg width={9} height={9} viewBox="0 0 10 10">
        <path d="M2.39,1.29,6.1,5,2.39,8.72a.75.75,0,0,0,0,1.06.77.77,0,0,0,1.07,0L7.54,5.71a1,1,0,0,0,0-1.41L3.46.22a.77.77,0,0,0-1.07,0A.77.77,0,0,0,2.39,1.29Z" />
    </svg>
);

const SeparatorIconBase = styled.i`
    padding: 0 1rem;
`;
