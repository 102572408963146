import {useEffect, useRef, useState} from "react";

export const useContentHeight = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | null>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                setContentHeight(contentRef.current?.offsetHeight || 0);
            });
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current);
            }
        };
    }, [contentRef.current, setContentHeight]);

    return {contentRef, contentHeight};
};
import {useEffect, useRef, useState} from "react";

export const useContentHeight = () => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | null>(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                setContentHeight(contentRef.current?.offsetHeight || 0);
            });
        });

        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                resizeObserver.unobserve(contentRef.current);
            }
        };
    }, [contentRef.current, setContentHeight]);

    return {contentRef, contentHeight};
};
