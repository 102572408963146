import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {ChevronDownIcon} from "@pg-design/icons";

import {useAccordionContext} from "../hooks/use_accordion_context";

export interface IProps {
    children: React.ReactNode;
    className?: string;
    icon?: React.ReactNode;
}

export const Header: React.FC<IProps> = (props) => {
    const context = useAccordionContext(Header.name);

    const navigationIconProps = {size: "2", fill: context.color};

    return (
        <HeaderBase
            css={props.className}
            background={context.background}
            bordered={context.bordered}
            borderRadius={context.borderRadius}
            collapsed={context.collapsed}
            onClick={context.onCollapse}
        >
            <Headline color={context.color}>
                {props.icon ? <span css={icon}>{props.icon}</span> : null} {props.children}
            </Headline>

            <span css={navigation && context.collapsed ? rotatedStyle : rotatedStyleReverse}>
                <ChevronDownIcon {...navigationIconProps} />
            </span>
        </HeaderBase>
    );
};

const HeaderBase = styled.div<{background?: string; bordered: boolean; borderRadius?: string; collapsed: boolean}>`
    width: 100%;
    min-height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.background || "#fff"};
    cursor: pointer;
    user-select: none;
    padding: 0.8rem 1.6rem;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : props.collapsed ? "1.6rem" : "1.6rem 1.6rem 0 0")};
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.secondary}` : "none")};
    border-bottom: ${(props) => (props.bordered && props.collapsed ? `1px solid ${props.theme.colors.secondary}` : "1px solid transparent")};
`;

const Headline = styled.div<{color?: string}>`
    display: flex;
    align-items: center;
    color: ${(props) => props.color || "initial"};
    width: 100%;
`;

const icon = css`
    margin-right: 0.8rem;
    position: relative;
    left: -0.8rem;
`;

const navigation = css`
    width: 5.2rem;
    min-width: 5.2rem;
    text-align: right;
    padding-right: 0.4rem;
`;

const rotatedStyle = css`
    transform: rotate(0deg);
    transition: ease-in-out 0.6s;
`;

const rotatedStyleReverse = css`
    transform: rotate(-180deg);
    transition: ease-in-out 0.6s;
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {ChevronDownIcon} from "@pg-design/icons";

import {useAccordionContext} from "../hooks/use_accordion_context";

export interface IProps {
    children: React.ReactNode;
    className?: string;
    icon?: React.ReactNode;
}

export const Header: React.FC<IProps> = (props) => {
    const context = useAccordionContext(Header.name);

    const navigationIconProps = {size: "2", fill: context.color};

    return (
        <HeaderBase
            css={props.className}
            background={context.background}
            bordered={context.bordered}
            borderRadius={context.borderRadius}
            collapsed={context.collapsed}
            onClick={context.onCollapse}
        >
            <Headline color={context.color}>
                {props.icon ? <span css={icon}>{props.icon}</span> : null} {props.children}
            </Headline>

            <span css={navigation && context.collapsed ? rotatedStyle : rotatedStyleReverse}>
                <ChevronDownIcon {...navigationIconProps} />
            </span>
        </HeaderBase>
    );
};

const HeaderBase = styled.div<{background?: string; bordered: boolean; borderRadius?: string; collapsed: boolean}>`
    width: 100%;
    min-height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.background || "#fff"};
    cursor: pointer;
    user-select: none;
    padding: 0.8rem 1.6rem;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : props.collapsed ? "1.6rem" : "1.6rem 1.6rem 0 0")};
    border: ${(props) => (props.bordered ? `1px solid ${props.theme.colors.secondary}` : "none")};
    border-bottom: ${(props) => (props.bordered && props.collapsed ? `1px solid ${props.theme.colors.secondary}` : "1px solid transparent")};
`;

const Headline = styled.div<{color?: string}>`
    display: flex;
    align-items: center;
    color: ${(props) => props.color || "initial"};
    width: 100%;
`;

const icon = css`
    margin-right: 0.8rem;
    position: relative;
    left: -0.8rem;
`;

const navigation = css`
    width: 5.2rem;
    min-width: 5.2rem;
    text-align: right;
    padding-right: 0.4rem;
`;

const rotatedStyle = css`
    transform: rotate(0deg);
    transition: ease-in-out 0.6s;
`;

const rotatedStyleReverse = css`
    transform: rotate(-180deg);
    transition: ease-in-out 0.6s;
`;
