import React, {useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {Container} from "@pg-design/grid";
import {calculateRemSize, mb, onDesktop, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IFaq, IFaqQuestion} from "../interfaces/IFaq";
import {FaqQuestion} from "./FaqQuestion";
import {FaqSchema} from "./FaqSchema";

interface IProps {
    className?: string;
    faqContent: IFaq[];
    genericFaqTitle?: string;
    genericQuestions?: IFaqQuestion[] | null;
    titleAlign?: "start" | "center" | "inherit" | "justify" | "left" | "right";
}

const FAQ_TITLE = "Często zadawane pytania";
export const FaqSection = (props: IProps) => {
    if (props.faqContent.length > 0 || (props.genericQuestions && !!props.genericQuestions.length)) {
        const schemaQuestions = useMemo(
            () =>
                props.faqContent
                    .reduce((acc, curr) => [...acc, ...curr.questions], [] as IFaqQuestion[])
                    .concat(props.genericQuestions && !!props.genericQuestions.length ? props.genericQuestions : []),
            [props.faqContent, props.genericQuestions]
        );
        const indexedTitles = useMemo(() => props.faqContent.reduce((acc, curr) => [...acc, curr.title], [] as string[]), [props.faqContent]);
        const addGenericToSection = useMemo(
            () => indexedTitles.findIndex((title) => title.trim() === FAQ_TITLE || title.trim() === props.genericFaqTitle?.trim()),
            [indexedTitles, props.genericFaqTitle]
        );
        const addGenericFaqSection = props.genericQuestions && props.genericQuestions.length > 0 && addGenericToSection < 0;

        return (
            <>
                {addGenericFaqSection && (
                    <Container css={[faqWrapper, mb(4)]} className={props.className}>
                        <Text as="h2" css={[mb(4), onDesktop(textAlign("center"))]} align={props.titleAlign} variant="headline_3">
                            {props.genericFaqTitle || FAQ_TITLE}
                        </Text>

                        <div css={[faqListWrapper]}>
                            {props.genericQuestions?.map((faqElement) => {
                                return <FaqQuestion key={faqElement.name} {...faqElement} />;
                            })}
                        </div>
                    </Container>
                )}

                {props.faqContent.map((faq, index) => {
                    const title = faq.title || (index === 0 && props.genericFaqTitle) || FAQ_TITLE;

                    return (
                        <Container key={title} css={[faqWrapper, mb(4)]} className={props.className}>
                            <Text as="h2" css={[mb(4), onDesktop(textAlign("center"))]} align={props.titleAlign} variant="headline_3">
                                {title}
                            </Text>

                            <div css={[faqListWrapper]}>
                                {props.genericQuestions &&
                                    index === addGenericToSection &&
                                    props.genericQuestions.map((faqElement) => {
                                        return <FaqQuestion key={faqElement.name} {...faqElement} />;
                                    })}
                                {faq.questions.map((faqElement) => {
                                    return <FaqQuestion key={faqElement.name} {...faqElement} />;
                                })}
                            </div>
                        </Container>
                    );
                })}

                <FaqSchema questions={schemaQuestions} />
            </>
        );
    }
    return <></>;
};

const faqWrapper = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 70rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        max-width: 90rem;
    }
`;

const faqListWrapper = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;
import React, {useMemo} from "react";
import {css, Theme} from "@emotion/react";

import {Container} from "@pg-design/grid";
import {calculateRemSize, mb, onDesktop, textAlign} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

import {IFaq, IFaqQuestion} from "../interfaces/IFaq";
import {FaqQuestion} from "./FaqQuestion";
import {FaqSchema} from "./FaqSchema";

interface IProps {
    className?: string;
    faqContent: IFaq[];
    genericFaqTitle?: string;
    genericQuestions?: IFaqQuestion[] | null;
    titleAlign?: "start" | "center" | "inherit" | "justify" | "left" | "right";
}

const FAQ_TITLE = "Często zadawane pytania";
export const FaqSection = (props: IProps) => {
    if (props.faqContent.length > 0 || (props.genericQuestions && !!props.genericQuestions.length)) {
        const schemaQuestions = useMemo(
            () =>
                props.faqContent
                    .reduce((acc, curr) => [...acc, ...curr.questions], [] as IFaqQuestion[])
                    .concat(props.genericQuestions && !!props.genericQuestions.length ? props.genericQuestions : []),
            [props.faqContent, props.genericQuestions]
        );
        const indexedTitles = useMemo(() => props.faqContent.reduce((acc, curr) => [...acc, curr.title], [] as string[]), [props.faqContent]);
        const addGenericToSection = useMemo(
            () => indexedTitles.findIndex((title) => title.trim() === FAQ_TITLE || title.trim() === props.genericFaqTitle?.trim()),
            [indexedTitles, props.genericFaqTitle]
        );
        const addGenericFaqSection = props.genericQuestions && props.genericQuestions.length > 0 && addGenericToSection < 0;

        return (
            <>
                {addGenericFaqSection && (
                    <Container css={[faqWrapper, mb(4)]} className={props.className}>
                        <Text as="h2" css={[mb(4), onDesktop(textAlign("center"))]} align={props.titleAlign} variant="headline_3">
                            {props.genericFaqTitle || FAQ_TITLE}
                        </Text>

                        <div css={[faqListWrapper]}>
                            {props.genericQuestions?.map((faqElement) => {
                                return <FaqQuestion key={faqElement.name} {...faqElement} />;
                            })}
                        </div>
                    </Container>
                )}

                {props.faqContent.map((faq, index) => {
                    const title = faq.title || (index === 0 && props.genericFaqTitle) || FAQ_TITLE;

                    return (
                        <Container key={title} css={[faqWrapper, mb(4)]} className={props.className}>
                            <Text as="h2" css={[mb(4), onDesktop(textAlign("center"))]} align={props.titleAlign} variant="headline_3">
                                {title}
                            </Text>

                            <div css={[faqListWrapper]}>
                                {props.genericQuestions &&
                                    index === addGenericToSection &&
                                    props.genericQuestions.map((faqElement) => {
                                        return <FaqQuestion key={faqElement.name} {...faqElement} />;
                                    })}
                                {faq.questions.map((faqElement) => {
                                    return <FaqQuestion key={faqElement.name} {...faqElement} />;
                                })}
                            </div>
                        </Container>
                    );
                })}

                <FaqSchema questions={schemaQuestions} />
            </>
        );
    }
    return <></>;
};

const faqWrapper = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        max-width: 70rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        max-width: 90rem;
    }
`;

const faqListWrapper = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(3)};
`;
